//functional component to create a form to upload the invoice image and extract the data from it.
//Call the API to extract the data and display it on the screen.

import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import AIService from "../services/AIService";

const Resumebytext = () => {
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [resume_text, setResumeText] = useState("");
    const [responsedata, setResponseData] = useState("");
  
    //method to handle the form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("key", key);
        formData.append("resume_text", resume_text);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Loading...";
        submitButton.style.backgroundColor = "#6c757d";

        //calling the extract method from AIService
        const response = await AIService.clean_resume(formData);
        console.log(response);

        //Change button text back to "Submit" after response
        submitButton.disabled = false;
        submitButton.innerText = "Submit";
        submitButton.style.backgroundColor = "#0d6efd";

        //setting the data in the state
        setResponseData(response);
    };

    return (
        <div className="container-fluid">
            <div className="row px-4 py-4">
                <div className="col-md-4">
                    <Card className="card">
                        <Card.Body>
                            <Card.Title> Resume Formatting </Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label> Upload Resume Text </Form.Label>
                                     {/*Multiline textbox */}
                                    <Form.Control
                                        as="textarea"
                                        rows={20}
                                        onChange={(e) => setResumeText(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-8">
                    <div className="data">
                        <h3> Formatted Resume</h3>

                        {/* Check if response data is not empty and reponsedata.status is success*/}
                        {responsedata ? (
                            <div class="invoice">
                                <div class="invoice-details mt-4">
                                    <p><strong>Name:</strong> {responsedata.name}</p>
                                    <p><strong>Email:</strong> {responsedata.email}</p>
                                    <p><strong>Contact Number:</strong> {responsedata.contact_number}</p>
                                    <p><strong>LinkedIn Profile:</strong> {responsedata.linkedin_id}</p>
                                    <p><strong>Executive Summary:</strong><br/> {responsedata.executive_summary}</p>

                                    {responsedata.educational_qualifications ? (
                                    <div>
                                        <strong>Educational Qualification:</strong><br/>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Degree</th>
                                                    <th>Year of Graduation</th>
                                                    <th>Institute Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responsedata.educational_qualifications.map((item) => (
                                                    <tr>
                                                        <td>{item.degree}</td>
                                                        <td>{item.year_of_graduation}</td>
                                                        <td>{item.institution_name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    ): (
                                        <div></div>
                                    )}

                                    {responsedata.work_experience ? (
                                    <div>
                                        <strong>Work Experience:</strong><br/>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Organization</th>
                                                    <th>Role</th>
                                                    <th>Role Description</th>
                                                    <th>Period</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responsedata.work_experience.map((item) => (
                                                    <tr>
                                                        <td>{item.organization}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.role_description}</td>
                                                        <td>{item.timeline}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    ): (
                                        <div></div>
                                    )}

                                    {responsedata.key_skills ? (
                                    <div>
                                        <strong>Key Skills:</strong><br/>
                                        {responsedata.key_skills.map((item) => (
                                            <span class="badge bg-secondary">{item} </span>
                                        ))}
                                    </div>
                                    ): (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p> No data found </p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resumebytext;
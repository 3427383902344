//functional component to upload the challan image and extract the data from it.
//Call the API to extract the data and display it on the screen.

import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import AIService from "../services/AIService";

const Shortlisting = () => {
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [jobDescription, setJobDescription] = useState("");
    const [responsedata, setResponseData] = useState("");

    //method to handle the form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("resume", file);
        formData.append("key", key);
        formData.append("job_description", jobDescription);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Loading...";
        submitButton.style.backgroundColor = "#6c757d";

        //calling the extract method from AIService
        const response = await AIService.shortlist(formData);
        console.log(response);

        //Change button text back to "Submit" after response
        submitButton.disabled = false;
        submitButton.innerText = "Submit";
        submitButton.style.backgroundColor = "#0d6efd";

        //setting the data in the state
        setResponseData(response);
    };

    //creating function to replace newlines with <br> in the report
    function replaceNewlinesWithBr(str) {
        return str.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }

    return (
        <div className="container-fluid">
            <div className="row px-4 py-4">
                <div className="col-md-4">
                    <Card className="card">
                        <Card.Body>
                            <Card.Title> Resume Shortlisting </Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formText" className="mb-3">
                                    <Form.Label> Job Description </Form.Label>
                                     {/*Multiline textbox */}
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        onChange={(e) => setJobDescription(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label> Upload Resume File </Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-8">
                    <div className="data">
                        <h3> Analysis</h3>

                        {/* Check if response data is not empty and reponsedata.status is success*/}
                        {responsedata ? (
                            <div class="invoice">
                                <div class="invoice-details mt-4">
                                    <p><strong>Name:</strong> {responsedata.name}</p>
                                    <p><strong>Email:</strong> {responsedata.email_id}</p>
                                    <p><strong>Contact Number:</strong> {responsedata.phone_number}</p>
                                    <p><strong>Shortlisted: </strong> 
                                    
                                        {responsedata.shortlist ? (
                                            <span>True</span>
                                        )                                        
                                        : (
                                            <span>False</span>
                                        )   
                                        }
                                    
                                    </p>
                                    <p><strong>Insights:</strong><br/> {responsedata.insight}</p>
                                    <p><strong>Detailed Report:</strong><br/> {replaceNewlinesWithBr(responsedata.report)}</p>                                    
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p> No data found </p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};


export default Shortlisting;
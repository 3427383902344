//functional component to display the dashboard with two cards for invoice and challan. 
//By clicking on the card, the user will be redirected to the respective page.

import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const Dashboard = () => {
    return (
        <>
            <div class="container py-3">
                <header>
                    <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="/" class="d-flex align-items-center link-body-emphasis text-decoration-none">
                            <span class="fs-4">Workpod AI Features</span>
                        </a>
                    </div>

                    <div class="pricing-header py-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-6 fw-normal text-body-emphasis">Resume Formatting and Shortlisting</h1>                        
                    </div>
                </header>

                <main>
                    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center mt-3">
                        <div class="col">
                            <div class="card mb-4 rounded-3 shadow-sm">
                                <div class="card-header py-3">
                                    <h4 class="my-0 fw-normal">Resume Formatting</h4>
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title pricing-card-title mb-5">Resume Formatting by uploading file</h5>
                                    <Link to="/resume-byfile">
                                        <button type="button" class="w-50 btn btn-lg btn-outline-primary">Get started</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card mb-4 rounded-3 shadow-sm">
                                <div class="card-header py-3">
                                    <h4 class="my-0 fw-normal">Resume Formatting</h4>
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title pricing-card-title mb-5">Resume Formatting by uploading text</h5>
                                    <Link to="/resume-bytext">
                                        <button type="button" class="w-50 btn btn-lg btn-outline-primary">Get started</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card mb-4 rounded-3 shadow-sm">
                                <div class="card-header py-3">
                                    <h4 class="my-0 fw-normal">Resume Shortlisting</h4>
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title pricing-card-title mb-5">Deep Resume Analysis</h5>
                                    <Link to="/resume-shortlisting">
                                        <button type="button" class="w-50 btn btn-lg btn-outline-primary">Get started</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>


                </main>
            </div>
        </>
    );
};

export default Dashboard;

import logo from './logo.svg';
import './App.css';

//import routes and route from react-router-dom
import { Routes, Route } from "react-router-dom";

//import pages
import Dashboard from "./pages/dashboard";
import Resumebyfile from "./pages/resume-byfile";
import Resumebytext from "./pages/resume-bytext";
import Shortlisting from './pages/shortlisting';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/resume-byfile" element={<Resumebyfile />} />
        <Route path="/resume-bytext" element={<Resumebytext />} />
        <Route path="/resume-shortlisting" element={<Shortlisting />} />
      </Routes>
    </>
  );
}

export default App;
